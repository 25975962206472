import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import MainTemplate from 'components/templates/MainTemplate';
import { title, metaKeywords, metaDescription, heading } from 'constants/meta';
import { catalogDescription } from 'constants/texts';
import categoriesQuery from 'graphql/queries/categories.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import { Ul, Li } from 'components/atoms/List';
import Link from 'components/atoms/Link';
import CategoriesTreeProvider from './CategoriesTreeProvider';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Description = styled.div`
  line-height: 1.5;
`;

const CatalogPage = () => (
  <Query query={categoriesQuery} notifyOnNetworkStatusChange>
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      return (
        <MainTemplate>
          <Helmet>
            <title>{title.catalog}</title>
            <meta name="keywords" content={metaKeywords.catalog} />
            <meta name="description" content={metaDescription.catalog} />
          </Helmet>
          <Title>{heading.catalog}</Title>
          <Description>{catalogDescription}</Description>
          <Ul>
            <CategoriesTreeProvider categories={data.categories}>
              {(tree) =>
                tree.map((root) => (
                  <Li key={root.id}>
                    <Link to={`/catalog/${root.translit}`}>{root.name}</Link>
                    {root.children.length > 0 && (
                      <Ul>
                        {root.children.map((child) => (
                          <Li key={child.id}>
                            <Link to={`/catalog/${child.translit}`}>{child.name}</Link>
                          </Li>
                        ))}
                      </Ul>
                    )}
                  </Li>
                ))
              }
            </CategoriesTreeProvider>
          </Ul>
        </MainTemplate>
      );
    }}
  </Query>
);

export default CatalogPage;
