const CategoriesTreeProvider = ({ categories, children }) => {
  const _categories = categories.map((category) => ({
    ...category,
    children: [],
  }));

  const categoriesById = _categories.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});

  const roots = _categories.filter((category) => !category.parent);

  _categories.forEach((category) => {
    if (!category.parent) {
      return;
    }

    categoriesById[category.parent.id].children = [
      ...categoriesById[category.parent.id].children,
      category,
    ];
  });

  return children(roots);
};

export default CategoriesTreeProvider;
